import React, { useState, useEffect, useRef } from "react";
import { FormControl, MenuItem, Select, Dialog, DialogTitle, DialogContent, CircularProgress, DialogActions, Button, Link, IconButton } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { config } from "../../Constants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useAuth from "../../context/AuthProvider";
import { EmailTemplateModel } from "../../models/EmailTemplateModel";
import { Label } from "@mui/icons-material";
import { apiClient } from "../../helper/api";
import { isStaffUser } from "../../helper/utils";
import DOMPurify from 'dompurify';
import { UploadedDocumentType } from "../../models/TaxTransactionReportModel";
import Tooltip from '@mui/material/Tooltip';
import Swal from "sweetalert2";
import { AddDocument } from "../../CustomerPanel/AddDocuments";
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce'

interface MessageDialogProps {
  open: boolean,
  custPolicyID: string,
  onSubmit?: (val?: any) => void,
  onClose?: () => void,
}

interface MessageProps {
  customerpolicyid: string,
  messageId: string,
  createdBy: string,
  messageType: string,
  message: string,
  createdByName: string,
  isRead: string,
  isEdited: string,
  isDeleted: string,
  dateCreated: string,
  dateModified: string,
  isResponse: string,
  createdByRoleId: number
}

interface RequiredDocument {
  srdmId: number;
  documentName: string;
  isRequired: boolean;
}

const MessageDialog: React.FC<MessageDialogProps> = ({ open, custPolicyID, onSubmit, onClose }) => {
  const [attachments, setAttachments] = useState<File[]>([]);
  const [messages, setMessages] = useState<MessageProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [EmailTemplateData, setEmailTemplateData] = useState<EmailTemplateModel[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplateModel | null>();
  const [message, setMessage] = useState<{ reply: string }>({ reply: "" });
  const [isResponse, setCustomerRespond] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [templateContent, setTemplateContent] = useState<string>("");
  const [UploadedDocument, setUploadedDocument] = React.useState<UploadedDocumentType[]>([]);
  const [openDoc, setOpenDoc] = useState(false);
  const [itemList, setItemList] = useState<RequiredDocument[]>([])
  const [contentVal, setContentVal] = useState<string>('');
  const editorRef = useRef<TinyMCEEditor | null>(null);

  const { user } = useAuth();
  const token = user?.token;
  const sanitizeHTML = (html: any) => DOMPurify.sanitize(html);

  const handleTemplateChange = (val: string) => {
    const selected = EmailTemplateData.find(
      (item: any) => item.emailTemplateId.toString() === val
    );
    setSelectedTemplate(selected);

    if (selected) {
      debugger
      const updatedContent = selected?.content
        ? selected.content
          // Force table width to 100%, set layout to fixed, and ensure full border styling
          .replace(
            /<table(.*?)style="(.*?)width:\s*\d+(\.\d+)?%(.*?);(.*?)"/g,
            `<table$1class="table-custom-style" style="$2width: 100% !important; table-layout: fixed; border-collapse: collapse; border: 1px solid black; $4;$5"`
          )
          // Apply left alignment, full borders, and padding inside <td> and <th>
          .replace(
            /<td(.*?)style="(.*?)"/g,
            `<td$1style="$2text-align: left !important; vertical-align: top !important; border: 1px solid black !important; padding: 8px !important;"`
          )
          .replace(
            /<th(.*?)style="(.*?)"/g,
            `<th$1style="$2text-align: left !important; vertical-align: top !important; border: 1px solid black !important; padding: 8px !important;"`
          )
        : "";
      setTemplateContent(updatedContent || ""); // Set the content of the selected template
      setConfirmDialogOpen(true); // Open the confirmation dialog
    }
  };

  const handleConfirmTemplate = async () => {
    console.log('Updated Template Content:', templateContent); // Log the updated content
    await createMessage(templateContent);
    setTemplateContent('');
    setSelectedTemplate(null);
    fetchMessages();
    setConfirmDialogOpen(false); // Close the dialog
    onSubmit?.(templateContent);
  };

  const validationSchema = Yup.object({
    reply: Yup.string().required("Reply is required"),
  });

  const UploadDocument = () => {
    setOpenDoc(true);
  }

  const handleClose = () => {
    getUploadedDocuments()
    fetchRequiredDocument()
    setOpenDoc(false)
  };

  const getUploadedDocuments = () => {
    apiClient(`/customer/getUploadedDocuments?customerPolicyId=${custPolicyID}`,
      {
        method: "GET",
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        })
      }).then((res) => {
        if (!res) return
        setUploadedDocument(res.data);
      })
  }

  const fetchRequiredDocument = () => {
    apiClient(`/customer/getRequiredDocuments?customerPolicyId=${custPolicyID}&all=0`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then(responseJson => {
        if (responseJson.data && responseJson.data.length > 0) {
          setItemList(responseJson.data);
        } else {
          setItemList([]);
        }
      })
      .catch(error => {
        setItemList([]);
        setLoading(false);
      });
  };

  const createMessage = (reply: string) => {
    const formData = new FormData();
    formData.append("CustomerPolicyId", custPolicyID);
    formData.append("message", reply);
    formData.append("isResponse", 'true');

    setLoading(true);
    apiClient(`/messages/createmessage/`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
      body: formData,
    }).then((res) => {
      if (!res) return;
      Swal.fire({
        title: 'Message Sent Succesfully',
        text: res.data,
        icon: res.data,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok'
      });
    }).finally(() => {
      setLoading(false);
    });
  }

  const handleSubmit = async (values: { reply: string }, { setSubmitting }: any) => {
    await createMessage(values.reply)
    setSubmitting(false);

    onSubmit?.(values.reply)
  };

  const fetchEmailTemplates = () => {
    apiClient("/EmailTemplate/getAll", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    }).then((responseJson) => {
      if (!responseJson) return
      setEmailTemplateData(responseJson.data || []);
    }).catch((error) => {
      console.error("Error fetching messages:", error.message);
      setEmailTemplateData([]);
    }).finally(() => {
    });
  };

  const fetchMessages = () => {
    apiClient(`/messages/getMessages?CustomerPolicyId=${custPolicyID}`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    }).then((responseJson) => {
      if (!responseJson) return
      // Ensure the response has the expected data structure
      if (responseJson && responseJson.data && responseJson.data.getMessages_Respdata?.length > 0) {
        setMessages(responseJson.data.getMessages_Respdata); // Set messages in state
      } else {
        setMessages([]); // Set empty messages if no data is returned
      }
    }).finally(() => {
      setLoading(false); // Stop loading spinner
    });
  }

  useEffect(() => {
    if (!open) {
      setAttachments([]);
      setMessages([]); // Clear messages on error
      return
    }

    setLoading(true);
    fetchEmailTemplates();
    getUploadedDocuments();
    fetchRequiredDocument();
    fetchMessages();
  }, [open]);

  const isReply = () => {
    if (isStaffUser(user?.userRoles)) {
      return true
    } else {
      return messages[0]?.isResponse ? true : false
    }
  }

  function ViewDocuments(props: { item: UploadedDocumentType }) {
    const { item } = props;

    return (
      <div className="box-style col-lg-12">
        {/* Tooltip for download link */}
        <Tooltip
          title={`Download ${item.documentName.split("_").slice(1).join("_")}`}
          arrow
        >
          <Link
            href={item.blob_Address}
            sx={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
            }}
            target="_blank"
          >
            <div
              role="presentation"
              tabIndex={0}
              style={{
                flex: "1 1 0%",
                display: "flex",
                alignItems: "center",
                padding: "10px",
                borderWidth: "1px",
                borderRadius: "8px",
                borderColor: "rgb(65, 114, 159)",
                borderStyle: "dashed",
                backgroundColor: "rgb(250, 250, 250)",
                color: "rgb(65, 114, 159)",
                outline: "none",
                transition: "border 0.24s ease-in-out",
                cursor: "pointer",
              }}
            >
              <span style={{ marginRight: "8px" }}>
                <p style={{ margin: 0 }}>{item.name}</p>
              </span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.25 13.75V17.4167C19.25 17.9029 19.0568 18.3692 18.713 18.713C18.3692 19.0568 17.9029 19.25 17.4167 19.25H4.58333C4.0971 19.25 3.63079 19.0568 3.28697 18.713C2.94315 18.3692 2.75 17.9029 2.75 17.4167V13.75M6.41667 9.16667L11 13.75M11 13.75L15.5833 9.16667M11 13.75V2.75"
                  stroke="#02A4E3"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </div>
          </Link>
        </Tooltip>

        {/* Document name display */}
        <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
          <p
            style={{
              height: "20px",
              textAlign: "start",
              margin: "2px 0",
              color: "#555",
              fontSize: "0.9rem",
            }}
          >
            {item.documentName.split("_").slice(1).join("_")}
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth={false} // Disable max-width restrictions
        PaperProps={{
          style: {
            width: '95%', // 90% of the viewport width
            height: '95%', // 90% of the viewport height
            margin: 'auto', // Center it on the screen
          },
        }}
      >
        <DialogTitle style={{ backgroundColor: "#e8f5fe", color: "#1976D2", fontSize: '25px' }}>
          INBOX
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#f4f9fc" }}>
          <Formik
            initialValues={message}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ values, isSubmitting, handleChange, errors, touched }) => (
              <Form style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <div style={{ display: "flex", gap: "20px", flex: "1", padding: "10px 0", height: "65%" }}>
                  <div style={{ flex: 1 }}>
                    <h3 style={{ color: "#00A9E0", marginBottom: "0", fontWeight: "bold", fontSize: '20px' }}>MESSAGE</h3>
                    <div
                      style={{
                        height: "95%", // Set a fixed height for the scrollable area
                        overflowY: "auto", // Enable vertical scrolling
                        border: "1px solid #ddd", // Add border for clarity
                        padding: "10px",
                        borderRadius: "4px",
                      }}
                    >
                      <table
                        style={{
                          width: "100%",
                          borderCollapse: "collapse",
                        }}
                      >
                        <tbody>
                          {messages && messages.length > 0 ? (
                            messages.map((message, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    padding: "8px",
                                    verticalAlign: "top",
                                    borderBottom: "1px solid #ddd",
                                  }}
                                >
                                  {(() => {
                                    if (user?.userRoles === "Customer User") {
                                      return message.createdByRoleId === 6 ? message.createdByName || "Unknown" : "MISCStaff";
                                    } else {
                                      return message.createdByName || "Unknown";
                                    }
                                  })()}
                                </td>
                                <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
                                  {/* Render HTML content or plain text */}
                                  {/* {message.message ? (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: message.message,
                                      }}
                                      style={{
                                        fontSize: "0.9rem",
                                        color: "#333",
                                        lineHeight: "1.5",
                                      }}
                                    />
                                  ) : (
                                    <span>No message content</span>
                                  )} */}
                                  <div
                                    style={{
                                      fontSize: "0.9rem",
                                      color: "#333",
                                      lineHeight: "1.5",
                                      maxWidth: "100%",
                                      overflowX: "auto",
                                      whiteSpace: "normal",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {message.message ? (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: sanitizeHTML(message.message),
                                        }}
                                        style={{
                                          overflowX: "auto",
                                          whiteSpace: "normal",
                                          wordWrap: "break-word",
                                          maxWidth: "100%",
                                        }}
                                      />
                                    ) : (
                                      <span>No message content</span>
                                    )}
                                  </div>
                                  <br />
                                  {/* Render the date */}
                                  <span style={{ fontSize: "0.85em", color: "#888" }}>
                                    {message.dateCreated
                                      ? new Date(message.dateCreated).toLocaleString()
                                      : "Unknown date"}
                                  </span>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={2} style={{ textAlign: "center", padding: "8px" }}>
                                No messages
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* Right Side: Attachments */}
                  <div style={{ flex: 1 }}>
                    {(isStaffUser(user?.userRoles) &&
                      <div>
                        <h3 style={{ color: "#00A9E0", fontWeight: "bold", fontSize: '20px', margin: "7px" }}>Message Templates</h3>
                        <FormControl fullWidth>
                          <Select
                            value={selectedTemplate?.emailTemplateId || ''}
                            onChange={(event) => handleTemplateChange(event.target.value.toString())}
                            displayEmpty
                          >
                            <MenuItem value="" disabled>
                              Select a Template
                            </MenuItem>
                            {EmailTemplateData.map((item) => (
                              <MenuItem key={item.emailTemplateId} value={item.emailTemplateId.toString()}>
                                {item.subject}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>)
                    }
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h3
                        style={{
                          color: "#00A9E0",
                          fontWeight: "bold",
                          fontSize: "20px",
                        }}
                      >
                        ATTACHMENTS
                      </h3>
                      {itemList.length > 0 &&
                        <button
                          onClick={() => UploadDocument()}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "10px 16px",
                            backgroundColor: "#1976D2",
                            color: "#fff",
                            border: "none",
                            cursor: "pointer",
                            borderRadius: "8px",
                            fontWeight: "bold",
                            fontSize: "14px",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            transition: "background-color 0.3s ease, transform 0.2s ease",
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = "#1565C0";
                            e.currentTarget.style.transform = "scale(1.05)";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = "#1976D2";
                            e.currentTarget.style.transform = "scale(1)";
                          }}
                        >
                          Upload Document
                        </button>
                      }
                    </div>
                    <div
                      style={{
                        height: "70%", // Set a fixed height for the scrollable area
                        overflowY: "auto", // Enable vertical scrolling
                        border: "1px solid #ddd", // Add border for clarity
                        padding: "10px",
                        borderRadius: "4px",
                        textAlign: "center",
                      }}
                    >
                      {UploadedDocument.length > 0 ?
                        UploadedDocument.map((item) => (<ViewDocuments item={item} />))
                        : <span style={{ textAlign: "center", padding: "8px" }}>No Attachments</span>
                      }
                    </div>
                  </div>
                </div>

                {/* Reply Section */}
                <div style={{ flex: "0 0 auto", marginTop: "0", marginBottom: "0" }}>
                  <h3 style={{ color: "#00A9E0", marginBottom: "0", fontWeight: "bold", fontSize: '20px' }}>REPLY</h3>
                  <Field
                    as="textarea"
                    name="reply"
                    placeholder="Type your reply here..."
                    style={{
                      width: "100%",
                      height: "100px",
                      padding: "10px",
                      border: "1px solid #ddd",
                      borderRadius: "4px",
                      fontFamily: "Arial, sans-serif",
                    }}
                    value={message.reply}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setMessage({ ...message, reply: e.target.value });
                    }}
                    disabled={(isReply() ? false : true)}
                  />
                  {isReply() && touched.reply && errors.reply && (
                    <p style={{ color: "red" }}>{errors.reply}</p>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center", // Align checkbox and buttons vertically
                      marginTop: "20px",
                    }}
                  >
                    {/* <div style={{ display: "flex", alignItems: "center", marginRight: "1%" }}>
                      {isStaffUser(user?.userRoles) && (
                        <>
                          <input
                            type="checkbox"
                            id="isResponse"
                            style={{ marginRight: "8px", cursor: "pointer" }}
                            onChange={(e) => setCustomerRespond(e.target.checked)} // Update state
                          />
                          <label
                            htmlFor="isResponse"
                            style={{ fontSize: "0.9rem", color: "#555", cursor: "pointer" }}
                          >
                            Customer Respond
                          </label>
                        </>
                      )}
                    </div> */}
                    <button
                      type="button"
                      onClick={onClose}
                      style={{
                        marginRight: "10px",
                        padding: "10px 20px",
                        backgroundColor: "#E0E0E0",
                        color: "#000",
                        border: "none",
                        cursor: "pointer",
                        borderRadius: "4px",
                        fontWeight: "bold",
                      }}
                    >
                      Cancel
                    </button>

                    {/* Send Button */}
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      style={{
                        padding: "10px 20px",
                        backgroundColor: isSubmitting ? "#A9A9A9" : "#1976D2", // Disable effect
                        color: "#fff",
                        border: "none",
                        cursor: isSubmitting ? "not-allowed" : "pointer",
                        borderRadius: "4px",
                        fontWeight: "bold",
                      }}
                    >
                      {isSubmitting ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        "Send"
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
        {/* Confirmation Dialog */}
        <Dialog
          open={confirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
          fullWidth
          maxWidth="xl" // Adjust dialog width as needed
        >
          <DialogTitle style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Confirm Template</DialogTitle>
          {/* <DialogContent style={{ minHeight: '400px', padding: '20px' }}>
            <div
              style={{
                overflowY: 'auto',
                maxHeight: '60vh',
                border: '1px solid #ccc',
                padding: '10px',
                backgroundColor: '#f9f9f9',
              }}
            >
              // Render content as HTML safely 
              <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(templateContent) }} />
            </div>
          </DialogContent> */}
          <Editor
            apiKey="qicc1jxe7rehdb1dttly5df1rzzivq8eim1yw5opxh1h7agy"
            value={templateContent}
            onEditorChange={(content) => setTemplateContent(content)} // Update state on change
            init={{
              plugins: [
                'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount', 'preview', 'fullscreen', 'quickbars', 'code',
              ],
              toolbar: 'undo redo | blocks fontfamily fontsize forecolor backcolor | bold italic underline strikethrough | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | link image media table | code fullscreen preview | removeformat',
              branding: false,
              quickbars_selection_toolbar: 'blocks fontfamily fontsize forecolor backcolor removeformat',
            }}
          />
          <DialogActions>
            <Button onClick={() => {
              setConfirmDialogOpen(false)
              setSelectedTemplate(null)
            }
            } color="secondary" variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={handleConfirmTemplate}
              color="primary"
              variant="contained"
              disabled={isReply() ? false : true} // Call the function to get the result
              style={{
                padding: "10px 20px",
                backgroundColor: (isReply() ? false : true) ? "#ccc" : "#1976D2", // Call the function here as well
                color: (isReply() ? false : true) ? "#888" : "#fff",
                cursor: (isReply() ? false : true) ? "not-allowed" : "pointer",
                borderRadius: "4px",
                fontWeight: "bold",
                transition: "background-color 0.3s ease, color 0.3s ease",
              }}
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
      <AddDocument open={openDoc} handleClose={handleClose} customerPolicyId={custPolicyID}></AddDocument>
    </>
  );
};

export default MessageDialog;