import * as yup from 'yup'
import useAuth from '../context/AuthProvider'

export interface UsersModel {
  userId: number,
  userName ?: string
  password ?: string
  isActive ?: boolean,
  isDeleted?: boolean,
  dateCreated: Date
  datemodified?: Date
  userEmail: string
  firstName: string
  lastName: String
  modifiedBy?: number,
  createdBy?: number,
  roleIds ?: string,
  partnersId: number,
  userRoles ?:string,
  customerName ?: string,
  commaSeparatedTransactionIds?: string,
  isCustomerRole?: boolean,
  isSIPAccess?: boolean,
  // profilePicture? : string,
  
}

export interface selectOptions {
  key: string,
  value: string
}

export const UsersModel_VS = yup.object({
 
  userName: yup.string().trim('user name cannot include leading and trailing spaces').strict(true)
   .min(1, 'user name needs to be at least 1 char')
   .max(300, 'user name cannot exceed 300 char')
   .required('This field is required'),
  
  userEmail: yup.string().email().required("Please enter Email").max(150, "Cannot exceed 150 character in length"),
  roleIds: yup.string().required("This field is required"),
  firstName: yup.string().trim('first name cannot include leading and trailing spaces').strict(true)
  .min(2,'First Name needs to be at least 2 char')
  .max(300, 'First Name can not exceed 300 char')
  .required("Please enter  First Name"),

  lastName: yup.string().trim('last name cannot include leading and trailing spaces').strict(true)
  .min(2,'last name needs to be at least 2 char')
  .max(300,'last name can not exceed more than 300 char')
  .required("Please enter Last Name"),
  // password: yup.string().required('No password provided.').min(8, 'Password is too short - should be 8 chars minimum.'),

  //roleIds:  yup.string().required("Please enter Role").typeError("inavlid value")
  // roleIds: yup.string().when('userRoles', {
  //   is: (userRoles: string) => !userRoles.split(",").includes("Super Admin"),
  //   then: yup.string().required("Please enter Role").typeError("Invalid value"),
  //   otherwise: yup.string()
  // }) 

  
})











