
interface ColumnItem {
  id: number
  field: string
  xlsHeader: string[]
  inputType: string
  excelType?: number
  duplicateId?: number | number[]
}
export const columnList: ColumnItem[] = [
  {
    //
    id: 100,
    field: '_rowNum',
    xlsHeader: ['_rowNum'],
    inputType: 'number',
  },
  {
    id: 0,
    field: 'invoiceNumber',
    xlsHeader: ['InvoiceNumber', 'Invoice', 'InvoiceNum'],
    inputType: 'string',
    duplicateId: undefined,
  }, {
    id: 1,
    field: 'insuredName',
    xlsHeader: ['InsuredName', 'InsuredPolicyName', 'IncuredName'],
    inputType: 'string',
  }, {
    id: 2,
    field: 'policyNumber',
    xlsHeader: ['PolicyNumber', 'Policy #', 'Pol #'],
    inputType: 'string',
  }, {
    id: 3,
    field: 'homeState',
    xlsHeader: ['HomeState', 'State', 'St'],
    inputType: 'string',
  }, {
    id: 4,
    field: 'taxType',
    xlsHeader: ['TaxType'],
    inputType: 'string',
    excelType: 1,
  }, {
    id: 5,
    field: 'taxPayee',
    xlsHeader: ['TaxPayee', 'PayeeName'],
    inputType: 'string',
    excelType: 1,
  }, {
    id: 6,
    field: 'transactionType',
    xlsHeader: ['TransactionType', 'Trans Type' , 'TransType'],
    inputType: 'string',
    excelType: 1,
  }, {
    id: 7,
    field: 'coverage',
    xlsHeader: ['Coverage', 'LOB', 'Program'],
    inputType: 'string',
  }, {
    id: 8,
    field: 'carrier',
    xlsHeader: ['Carrier'],
    inputType: 'string',
  }, {
    id: 9,
    field: 'naic',
    xlsHeader: ['NAIC'],
    inputType: 'string',
  }, {
    id: 10,
    field: 'transEffDate',
    xlsHeader: ['TransactionEffDate', 'Transaction Effective Date', 'Tran. Eff. Date', 'TransactionEffective'],
    inputType: 'date',
  }, {
    id: 11,
    field: 'invDate',
    xlsHeader: ['InvDate', 'Invoice Date'],
    inputType: 'date',
  }, {
    id: 12,
    field: 'policyEffDate',
    xlsHeader: ['PolicyEffDate', 'Policy Effective Date'],
    inputType: 'date',
  }, {
    id: 13,
    field: 'policyExpDate',
    xlsHeader: ['PolicyExpDate', 'Policy Expiration Date', 'Expiration Date'],
    inputType: 'date',
  }, {
    id: 14,
    field: 'grossPremium',
    xlsHeader: ['GrossPremium', 'Premium'],
    inputType: 'number',
  }, {
    id: 15,
    field: 'nonAdmittedPremium',
    xlsHeader: ['NonAdmittedPremium'],
    inputType: 'number',
    excelType: 1,
  }, {
    id: 16,
    field: 'taxableFees',
    xlsHeader: ['TaxableFees', 'Taxable Fee'],
    inputType: 'number',
  }, {
    id: 17,
    field: 'nonTaxableFees',
    xlsHeader: ['NonTaxableFees'],
    inputType: 'number',
  }, {
    id: 18,
    field: 'taxAmt',
    xlsHeader: ['TaxAmt', 'Tax Amount', 'TaxDue'],
    inputType: 'number',
  }, {
    id: 19,
    field: 'taxBalance',
    xlsHeader: ['TaxBalance'],
    inputType: 'number',
  }, {
    id: 20,
    field: 'client',
    xlsHeader: ['Client', 'Agency'],
    inputType: 'string',
  }, {
    id: 21,
    field: 'clientDiv',
    xlsHeader: ['ClientDiv', 'Client Division'],
    inputType: 'string',
    excelType: 1,
  }, {
    id: 22,
    field: 'dateInvoiceAdded',
    xlsHeader: ['DateInvoiceAdded'],
    inputType: 'string',
  }, {
    id: 23,
    field: 'clientNotes',
    xlsHeader: ['ClientNotes', 'Description'],
    inputType: 'string',
    excelType: 1,
  }, {
    id: 24,
    field: 'filerNotes',
    xlsHeader: ['FilerNotes'],
    inputType: 'string',
    excelType: 1,
  }, {
    id: 25,
    field: 'status',
    xlsHeader: ['Status'],
    inputType: 'string',
  }, {
    id: 26,
    field: 'firstRequest',
    xlsHeader: ['FirstRequest'],
    inputType: 'date',
  }, {
    id: 27,
    field: 'secondRequest',
    xlsHeader: ['SecondRequest'],
    inputType: 'date',
    excelType: 1,
}, {
    id: 28,
    field: 'thirdRequest',
    xlsHeader: ['ThirdRequest'],
    inputType: 'date',
    excelType: 1,
}, {
    id: 29,
    field: 'suspended',
    xlsHeader: ['Suspended'],
    inputType: 'date',
    excelType: 1,
}, {
    id: 30,
    field: 'dateFiled',
    xlsHeader: ['DateFiled'],
    inputType: 'date',
  }, {
    id: 31,
    field: 'filingType',
    xlsHeader: ['FilingType'],
    inputType: 'string',
  }, {
    id: 32,
    field: 'filerName',
    xlsHeader: ['FilerName'],
    inputType: 'string',   
  },
  {
    id: 33,
    field: 'bgiaAssignments',
    xlsHeader: ['BGIAAssignments'],
    inputType: 'string',
  }, {
    id: 34,
    field: 'removed',
    xlsHeader: ['Removed'],
    inputType: 'string',
  },  {
    id: 35,
    field: 'paymentPeriod',
    xlsHeader: ['PaymentPeriod'],
    inputType: 'string',
  }, {
    id: 48,
    field: 'path',
    xlsHeader: ['Path'],
    inputType: 'string',
  }, {
    id: 47,
    field: 'itemType',
    xlsHeader: ['ItemType'],
    inputType: 'string',
  },
  {
    id: 46,
    field: 'bor',
    xlsHeader: ['BOR'],
    inputType: 'string',
  },
  {
    id: 45,
    field: 'diligentSearchToGMAC',
    xlsHeader: ['DiligentSearchToGMAC'],
    inputType: 'string',
  }, {
    id: 44,
    field: 'diligentSearchCompleted',
    xlsHeader: ['DiligentSearchCompleted'],
    inputType: 'string',
  },{
    id: 43,
    field: 'receivedFromVenbrook',
    xlsHeader: ['ReceivedFromVenbrook'],
    inputType: 'string',
  },
  {
    id: 42,
    field: 'sentToVenbrook',
    xlsHeader: ['SentToVenbrook'],
    inputType: 'string',
  },
  {
    id: 41,
    field: 'diligentSearchProcessDate',
    xlsHeader: ['DiligentSearchProcessDate'],
    inputType: 'date',
  },
  {
    id: 40,
    field: 'diligentSearchNotes',
    xlsHeader: ['DiligentSearchNotes'],
    inputType: 'string',
  },
  {
    id: 39,
    field: 'needDiligentSearch',
    xlsHeader: ['NeedDiligentSearch'],
    inputType: 'string',
  },
  {
    id: 38,
    field: 'feeOnly',
    xlsHeader: ['FeeOnly'],
    inputType: 'string',
  },
  {
    id: 37,
    field: 'paymentNoticeDate',
    xlsHeader: ['PaymentNoticeDate'],
    inputType: 'date',
  },


  /**
   * 'Cardigan Jetfile Transactions' columns
   */
  {
    id: 26,
    field: 'homeState',
    xlsHeader: ['Tax State'],
    inputType: 'string',
    duplicateId: 3,
  }, {
    id: 27,
    field: 'whoFiles',
    xlsHeader: ['Who Files'],
    inputType: 'string',
    excelType: 2,
  }, {
    id: 28,
    field: 'isManualTax',
    xlsHeader: ['Manual Tax'],
    inputType: 'bool',
    excelType: 2,
  }, {
    id: 29,
    field: 'fileNumber',
    xlsHeader: ['File #'],
    inputType: 'number',
    excelType: 2,
  }, {
    //   id: 30,
    //   field: 'policyNumber',
    //   xlsHeader: ['Pol #'],
    //   inputType: 'string',
    //   duplicateId: 2,
    // }, {
    id: 31,
    field: 'endNumber',
    xlsHeader: ['End #'],
    inputType: 'number',
    excelType: 2,
  }, {
    id: 32,
    field: 'bookDate',
    xlsHeader: ['Book Date'],
    inputType: 'date',
    excelType: 2,
  }, {
    id: 33,
    field: 'insuredName',
    xlsHeader: ['Insured'],
    inputType: 'string',
    duplicateId: 1,
  }, {
    id: 34,
    field: 'invoiceNumber',
    xlsHeader: ['Inv #'],
    inputType: 'number',
    duplicateId: 0,
  }, {
    id: 35,
    field: 'invDate',
    xlsHeader: ['Inv Date'],
    inputType: 'date',
    duplicateId: 11,
  }, {
    id: 36,
    field: 'taxAmt',
    xlsHeader: ['Tax Base'],
    inputType: 'number',
  }, {
    id: 37,
    field: 'grossPremium',
    xlsHeader: ['Gross Prem'],
    inputType: 'number',
    duplicateId: 14,
  }, {
    id: 38,
    field: 'coFee',
    xlsHeader: ['Co Fee'],
    inputType: 'number',
    excelType: 2,
  }, {
    id: 39,
    field: 'coFeeByTodayTaxFlag',
    xlsHeader: ["Co Fee - by today's Tax Flags"],
    inputType: 'number',
    excelType: 2,
  }, {
    id: 40,
    field: 'coFeeDiff',
    xlsHeader: ['Co Fee - Diff'],
    inputType: 'number',
    excelType: 2,
  }, {
    id: 41,
    field: 'brokFee',
    xlsHeader: ['Brok Fee'],
    inputType: 'number',
    excelType: 2,
  }, {
    id: 42,
    field: 'brokFeeByTodayTax',
    xlsHeader: ["Brok Fee - by today's Tax Flags"],
    inputType: 'number',
    excelType: 2,
  }, {
    id: 43,
    field: 'brokFeeDiff',
    xlsHeader: ['Brok Fee - DIFF'],
    inputType: 'number',
    excelType: 2,
  }, {
    id: 44,
    field: 'inspFee',
    xlsHeader: ['Insp Fee'],
    inputType: 'number',
    excelType: 2,
  }, {
    id: 45,
    field: 'inspFeeByTodayTaxFlag',
    xlsHeader: ["Insp Fee - by today's Tax Flags"],
    inputType: 'number',
    excelType: 2,
  }, {
    id: 46,
    field: 'inspFeeDiff',
    xlsHeader: ['Insp Fee - DIFF'],
    inputType: 'number',
    excelType: 2,
  }, {
    id: 47,
    field: 'isInspFeeToCo',
    xlsHeader: ['Insp Fee to Co'],
    inputType: 'bool',
    excelType: 2,
  }, {
    id: 48,
    field: 'taxSL',
    xlsHeader: ['S/L Tax'],
    inputType: 'number',
    excelType: 2,
  }, {
    id: 49,
    field: 'stampFee',
    xlsHeader: ['Stamp Fee'],
    inputType: 'number',
    excelType: 2,
  }, {
    id: 50,
    field: 'otherFee',
    xlsHeader: ['Other Fee'],
    inputType: 'number',
    excelType: 2,
  }, {
    id: 51,
    field: 'otherTax',
    xlsHeader: ['Other Tax'],
    inputType: 'number',
    excelType: 2,
  }, {
    id: 52,
    field: 'otherTaxDescr',
    xlsHeader: ['Other Tax Descr'],
    inputType: 'string',
    excelType: 2,
  }, {
    id: 53,
    field: 'otherFeeDescr',
    xlsHeader: ['Other Fee Descr'],
    inputType: 'string',
    excelType: 2,
  }, {
    id: 54,
    field: 'acctOtherFee',
    xlsHeader: ['OthFee Acct'],
    inputType: 'string',
    excelType: 2,
  }, {
    id: 55,
    field: 'carrier',
    xlsHeader: ['Company'],
    inputType: 'string',
    duplicateId: 8,
  }, {
    id: 56,
    field: 'statusAdm',
    xlsHeader: ['Adm Status'],
    inputType: 'string',
    excelType: 2,
  }, {
    id: 57,
    field: 'isCoFeeTaxable',
    xlsHeader: ['Co Fee Taxable'],
    inputType: 'bool',
    excelType: 2,
  }, {
    id: 58,
    field: 'isBrFeeTaxable',
    xlsHeader: ['Br Fee Taxable'],
    inputType: 'bool',
    excelType: 2,
  }, {
    id: 59,
    field: 'isInspFeeTaxable',
    xlsHeader: ['Insp Fee Taxable'],
    inputType: 'bool',
    excelType: 2,
  }, {
    id: 60,
    field: 'taxExempt',
    xlsHeader: ['Tax Exempt'],
    inputType: 'string',
    excelType: 2,
  }, {
    id: 61,
    field: 'nonTaxBrFee',
    xlsHeader: ['Non-Tax Br Fee'],
    inputType: 'number',
    excelType: 2,
  }, {
    id: 62,
    field: 'coverage',
    xlsHeader: ['Line-Bus'],
    inputType: 'string',
    duplicateId: 7,
  }, {
    id: 63,
    field: 'underwriter',
    xlsHeader: ['Underwriter'],
    inputType: 'string',
  }, {
    id: 64,
    field: 'effDate',
    xlsHeader: ['Eff Date', 'Effective Date'],
    inputType: 'date',
    duplicateId: [10, 12],
  }, {
    id: 65,
    field: 'naic',
    xlsHeader: ['NAIC code'],
    inputType: 'string',
    duplicateId: 9,
  }, {
    id: 66,
    field: 'dateFiledWSLA',
    xlsHeader: ["Date Filed w/ SLA"],
    inputType: 'date',
    excelType: 2,
  }, {
    id: 67,
    field: 'filingBatch',
    xlsHeader: ['Filing Batch'],
    inputType: 'number',
    excelType: 2,
  }, {
    id: 68,
    field: 'isOroBatch',
    xlsHeader: ['ORO Batch'],
    inputType: 'bool',
    excelType: 2,
  }, {
    id: 69,
    field: 'tranCode',
    xlsHeader: ['Tran Code'],
    inputType: 'string',
    excelType: 2,
  }, {
    id: 70,
    field: 'taxNotes',
    xlsHeader: ['Tax Notes'],
    inputType: 'string',
    excelType: 2,
  }, {
    id: 101,
    field: 'incorrectField',
    xlsHeader: ['Incorrect Filed'],
    inputType: 'string',
  }
]
export const similarList: { [x: string]: { value: string, possibleValues: string[] }[] } = {
  transactionType: [
    {
      value: 'New Business',
      possibleValues: ['New Policy', 'New Business', 'New', 'NBS', 'NEW_BUS', 'Pol'],
    },
    {
      value: 'Renewal Business',
      possibleValues: ['Renewal Policy', 'Renewal Business', 'Renewal', 'Renewed', 'REN', 'RWL'],
    },
    {
      value: 'Endorsement',
      possibleValues: ['Endorsement', 'ADD', 'RET', 'AMEND', 'Endt', 'Endt 1', 'Endt 2', 'Endt 3', 'Endt 4','PAB', 'PCH'],
    },
    {
      value: 'Cancellation',
      possibleValues: ['Cancellation', 'CANCEL', 'CANREWRITE', 'CANREINSTALL', 'CXL'],
    },
    {
      value: 'Reinstatement',
      possibleValues: ['Reinstatement', 'REINSTATE', 'RMID'],
    },
    {
      value: 'Audit',
      possibleValues: ['Audit' , 'AUD'],
    },
  ],
}

export const transactionTypeList = [
  {
    value: 'New Business',
    possibleValues: ['New Business', 'New', 'NBS', 'NEW_BUS'],
  },
  {
    value: 'Renewal Business',
    possibleValues: ['Renewal Policy', 'Renewal Business', 'Renewal', 'Renewed', 'REN', 'RWL'],
  },
  {
    value: 'Endorsement',
    possibleValues: ['Endorsement', 'ADD', 'RET', 'AMEND', 'Endt', 'Endt 1', 'Endt 2', 'Endt 3', 'Endt 4' , 'PAB', 'PCH'],
  },
  {
    value: 'Cancellation',
    possibleValues: ['Cancellation', 'CANCEL', 'CANREWRITE', 'CANREINSTALL', 'CXL'],
  },
  {
    value: 'Reinstatement',
    possibleValues: ['Reinstatement', 'REINSTATE' , 'RMID'],
  },
  {
    value: 'Audit',
    possibleValues: ['Audit', 'AUD'],
  },
  {
    value: 'Policy',
    possibleValues: ['New Policy', 'Pol','Policy'],
  },
]

export const taxTypeList = [
  {
    value: 'Stamping Fee',
    possibleValues: ['Stamping Fee', 'Stamping Fees', 'Stamping Office Fee', 'FSLSO Fee', 'Quarterly Payment', 'Stamping Fee from Filing Schedule'],
  },
  {
    value: 'Surplus Lines Tax',
    possibleValues: ['Surplus Lines Tax', 'Surplus Lines Premium Tax', 'Premium Tax', 'FL DFS Premium Receipts Tax'],
  },
  // {
  //   value: 'FSLSO Fee',
  //   possibleValues: ['FSLSO Fee', 'Quarterly Payment', 'Stamping Fee from Filing Schedule'],
  // },
  {
    // TODO: need to check
    value: 'Clearinghouse Fee',
    possibleValues: ['Clearinghouse Fee', 'SLAS Quarterly Tax Payment', 'Clearinghouse Transaction Fee'],
  },
  {
    // TODO: need more update
    value: 'Municipal Tax',
    possibleValues: ['Municipal Tax'],
  },
  {
    value: "Windstorm Fee",
    possibleValues: ['MWUA Nonadmitted Policy Fees', 'MWUA Fee', 'MWUA Fee (3%)', 'MWUA Policy Fee' , 'Mississippi Windstorm Underwriting Association', "Mississippi's MWUA Fee"],
  },
  {
    // TODO: need more update
    value: 'Maintenance Assessment Fee',
    possibleValues: ['Maintenance Assessment Fee'],
  },
  {
    value: 'Regulatory Fee',
    possibleValues: ['Regulatory Fees'],
  },
  // Assesment Fee,
  //  EMPA Surcharge,
  // Surplus Lines Service Charge  , 
  // Fire Marshal Tax ,Fire Marshall Tax,
  // Other Tax, Florida Tax, 
  // SLIP Transaction Fee , 
  // State Tax ,
]

export const excelExportTypes = [
  // {
  //   excelType: 0,
  //   name: 'Reports',
  //   columnOrder: Array.from(Array(columnList.length).keys()).filter(idx => columnList[idx].duplicateId === undefined),
  // },
  {
    excelType: 1,
    name: 'OpenTaxReport',
    columnOrder: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25],
  },
  {
    excelType: 3,
    name: 'Missing Documents',
    columnOrder: [0, 93, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]
  },
  // {
  //   excelType: 2,
  //   name: 'CardiganJetfileTransactions',
  //   columnOrder: [26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70],
  // }
]

export const clientList = [
  {
    value: 'XPT Partners LLC',
    possibleValues: ['XPT', 'XPT Partner', 'XPT Partners', 'XPT Specialty LLC', 'XPT Specialty'],
  }
]

