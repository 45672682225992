import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, SelectProps } from "@mui/material";
import { FieldAttributes, useField, useFormikContext } from "formik";
import { useEffect, useMemo, useState } from "react";

    
    export const FMSelectRolesCustomcopy = ({ variant,UsersBL,Roles, ...props }:any) => {
        const selectedTypes: string[] = useMemo(() => {
            return UsersBL.roleIds ? UsersBL.roleIds.trim().split(',').map((item :string)=> item.trim()) : [];
          }, [UsersBL.roleIds]);


        const [field, meta] = useField<{}>(props);
        const { setFieldValue } = useFormikContext() ?? {};
        const errorText = meta.error && meta.touched ? meta.error : "";
        const [selectedOptions, setSelectedOption] = useState<string[]>([]);

        useEffect(()=>{
            setSelectedOption(selectedTypes)
        },[selectedTypes])
        
        return (
            <FormControl error={!!errorText} fullWidth>
                <FormHelperText className='formtexthelper-heading'>
                </FormHelperText>
                <FormGroup sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 1 }}>
                    {Roles.map((role:any) => {

                        return(
                        <FormControlLabel
                            key={role.roleId}
                            control={
                                <Checkbox
                                    checked={selectedOptions.includes(role.roleId.toString())}
                                    onChange={(e) => {
                                        let updatedOptions = e.target.checked
                                            ? [...selectedOptions, role.roleId.toString()]
                                            : selectedOptions.filter((id) => id !== role.roleId.toString());
                                        setSelectedOption(updatedOptions);
                                        setFieldValue("roleIds", updatedOptions.join(","));
                                    }}
                                />
                            }
                            label={role.roleName}
                        />
                    )})}
                </FormGroup>
                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>
        );
    };
    