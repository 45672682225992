import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { CustomerRightNav } from "../Nav/CustomerRightNav";
import { Box, LinearProgress } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import useAuth from "../../context/AuthProvider";
import Swal from "sweetalert2";
import Notification from "./Notification";

export default function CustomerLayout() {
  const [showRightNav, setShowRightNav] = useState(true);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }
  const [loading, setloading] = useState(false);
  const toggleRightNav = () => {
    setShowRightNav(!showRightNav);
  };
  const handleLogout = () => {
    Swal.fire({
        title: 'Log Out',
        text: "Are you sure you want to log out?",
        showCancelButton: true,
        confirmButtonColor: '#02A4E3',
        cancelButtonColor: '#d33',
        customClass: 'logout-popup',
        // iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        iconHtml: '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16"> \
                    <path d="M7.5 1v7h1V1z"/> \
                    <path d="M3 8.812a5 5 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812"/> \
                  </svg>',
    }).then((result) => {
      if (result.isConfirmed) {
        logOut();
        navigate('/login'); 
        return;
      }
    })
  };
  const { user, logout } = useAuth();
  const logOut = () => {
    logout();
  };
 
  return (
    <main>
      <section className="policy-dashboard-section">
        <div className="banner-section">
          <div className="img-bg-sec">


            <div className="right-nav-popup">
              <div className="nav-section">

                <nav className="navbar navbar-expand-lg navbar-light ">
                  <div className="container-fluid">

                    <a className="navbar-brand main-img nav-left" href="/">
                      <img src=" /assets/brand/MISC_Full_Type_Lockup_Primary.png" alt="" />
                    </a>

                    <div className="collapse navbar-collapse container-fluid" id="navbarTogglerDemo01">
                    <ul className="navbar-nav align-items-center nav-icons-ml" style={{ marginLeft: '95%' }}>
                    {/* <li className="nav-item bell tag">
                          <Notification />
                        </li> */}
                        {!user?.userRoles?.split(",").includes("Customer Processors") && (
                        <li className="nav-item menu tag">
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={toggleRightNav}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3 12H21M3 6H21M3 18H21"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </li>
                        )}
                        <li className="nav-item user tag" onClick={handleLogout} title={user?.userFirstName ?? ''}>
                          <a href="javascript:void(0)" className="profile-icon">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
                                stroke="white"
                                strokeWidth="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>

              </div>

            </div>
          </div>
          <CustomerRightNav Open={showRightNav} handleClose={toggleRightNav} />
        </div>

        <Box
          className="demo-2"
          sx={{
            // backgroundColor: "#ffffff",
            // height: "100%",
          }}
        >
          <Box
            className="layout-padding"
            sx={{
              // height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </section>
    </main >
  );
}
