import React, { useEffect, useState } from "react";
import useAuth, { authData } from "../../context/AuthProvider";
import { Box, Button, Dialog, DialogContent, IconButton, LinearProgress, Modal, Typography } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloseIcon from "@mui/icons-material/Close";
import { config } from '../../Constants';
import Swal from "sweetalert2";
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import { apiClient } from "../../helper/api";

var formData = new FormData();
interface Iprops {

  handleClose: () => void,
  Open: boolean,
}
interface ProfilePictureModalProps {
  open: boolean;
  onClose: () => void;
  currentPicture: string;
  onUpdatePicture: (newPicture: string) => void;
  // userFirstName : string

}
const ProfilePictureModal: React.FC<ProfilePictureModalProps> = ({
  open,
  onClose,
  currentPicture,
  onUpdatePicture,
  // userFirstName

}) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      formData.delete('image');
      formData.append('image', file);
      setSelectedImage(file);
    }
  };

  const [loading, setloading] = useState(false);
  const handleUpload = () => {
    if (selectedImage) {
      setloading(true);
      const objectURL = URL.createObjectURL(selectedImage);
      // onUpdatePicture(objectURL);
      //Simulate server upload
      setTimeout(() => {
        onUpdatePicture(objectURL);
        setloading(false);
      }
        , 6000
      );

    }
  };
  const handleCloseImage = () => {
    setSelectedImage(null);
    formData.delete('image');
    onClose();
  };


  return (
    <Dialog open={open} onClose={onClose} maxWidth='lg'  >

      {loading && <LinearProgress style={{ margin: 2 }} />}
      <DialogContent
        sx={{
          // width: 380,
          // height: 480,
          // bgcolor: "background.paper",
          // boxShadow: 24,
          borderRadius: "25px",
          textAlign: "center",
          outline: "none",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "0px",
          position: "relative",
        }}
      >
        <IconButton
          onClick={handleCloseImage}
          sx={{
            position: "absolute",
            top: -10,
            right: 0,
            color: "#333",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          variant="h5"
          component="h2"
          sx={{ mb: 3, fontWeight: "bold", fontSize: "20px" }}
        >
          Edit Profile Picture
        </Typography>
        <Box
          sx={{
            width: "300px",
            height: "300px",
            borderRadius: "50%",
            border: "4px solid #ddd",
            overflow: "hidden",
            mb: 2,
            position: "relative",
          }}
        >
          <img
            src={selectedImage ? URL.createObjectURL(selectedImage) : currentPicture}
            alt="Current"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
          <IconButton
            component="label"
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              bgcolor: "rgba(0, 0, 0, 0.6)",
              color: "#fff",
              borderRadius: "50%",
              p: 3,
              m: 3,
            }}
          >
            <CameraAltIcon />
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="profile-picture-upload"
              type="file"
              onChange={handleFileChange}
            />
          </IconButton>
        </Box>
        {/* <h4 className="m-0">{userFirstName}</h4> */}
        {/* <b>{userFirstName}</b> */}
        <Box  >
          <div className="d-flex">


            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              className="btn-profile-save me-2 "
            >
              {loading ? "Saving..." : "Save"}
            </Button>
            <div>

            </div>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCloseImage}
              className="btn-profile-cancel"
            >
              Cancel
            </Button> </div>
        </Box>
      </DialogContent>

    </Dialog>
  );
};


export const CustomerRightNav: React.FC<Iprops> = ({ handleClose, Open }) => {
  const { user, logout } = useAuth();


  const logOut = () => {
    logout();
  };

  const token = user?.token;
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  interface UserData {
    userId: number;

  }
  let userStr: authData | undefined = user;

  // const [userProfile , setUserProfile] = useState(userStr.profilePicture);
  const [userProfile, setUserProfile] = useState<string | null>(userStr?.profilePicture ?? "");




  const [loading, setloading] = useState(false);

  const handleUpdatePicture = (newPicture: string) => {
    debugger;

    const initValues: UserData = {
      userId: user?.userId ?? 0,

    };
    formData.append("data", JSON.stringify(initValues))
    console.log("finalData", initValues)
    setloading(true);
    apiClient(`/Users/UpdateProfilePicture`, {

      method: "POST",
      headers: new Headers({
        // "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: formData


    })
      .then((res) => {
        if (!res) return
        if (res.resp == 0) {

          // let userStr : authData | undefined =  localStorage.getItem("user");
          // userStr.profilePicture = res.data;
          console.log("profleurl", res.data);
          console.log("loalstorageData", userStr);


          if (userStr != undefined) {
            userStr.profilePicture = res.data
            localStorage.removeItem("user");
            localStorage.setItem("user", JSON.stringify(userStr));
            setUserProfile(res.data);
          }


          Swal.fire({
            title: "Profile Picture Updated Successfully",
            text: "",
            iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            confirmButtonColor: '#02A4E3',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn-radius',
              cancelButton: 'btn-radius',
              popup: 'card-radius'
            }
          })
            .then((result) => {
              formData.delete("data");
              formData.delete("image");
              handleModalClose();
            })
        }
        setloading(false);
      })
      .finally(() => {
        setloading(false);
      });

  };

  const fullName = (user?.userFirstName ?? "") + " " + (user?.userLastName ?? "");

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "/assets/dist/js/myscripts.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };

  }, []);
  return (
    <div hidden={Open} className="me-n1 p-4 bg-dm-light nav-wrapper custom-style-nav" >
      <div className="d-flex justify-content-between align-items-center">
        <img
          src="/assets/brand/MISC_Brandmark_Primary.png"
          className="align-self-center"
          style={{ width: "34px", height: "35px" }}
          alt="FileMule"
        ></img>
        <ul className="ms-auto d-flex mb-0 flex-wrap list-unstyled">
          <li className="px-2">
            <a onClick={handleClose} href="javascript:void(0)">
              <svg
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.25 7.75L7.75 23.25M7.75 7.75L23.25 23.25"
                  stroke="white"
                  strokeWidth="2.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          </li>
        </ul>
      </div>

      <div className="d-flex justify-content-center align-items-center round-img-sec">
        <div
          className="profile-circle-img me-2"
          onClick={handleModalOpen}
          style={{
            cursor: "pointer",
            borderRadius: "50%",
            overflow: "hidden",
            width: "70px",
            height: "70px",
          }}
        >
          {user?.profilePicture !== null ? (
            <img
              src={userProfile ?? ""}
              // src={userStr.profilePicture}

              alt="Blob Image"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            <img
              src="/assets/brand/Screen Shot 2024-03-04 at 5.16 1.png"
              alt="Avatar"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          )}
        </div>
        <div>
          <p className="round-img-p">
            Hi, <b>{user?.userFirstName}</b>
          </p>
        </div>
      </div>

      <ProfilePictureModal
        open={modalOpen}
        onClose={handleModalClose}
        currentPicture={
          userProfile ||
          "/assets/brand/Screen Shot 2024-03-04 at 5.16 1.png"
        }
        onUpdatePicture={handleUpdatePicture}
      // userFirstName ={fullName}

      />


      <div className="nav-bar-right-sides">
        <ul className="list-unstyled mt-3 navigation">
          {(user?.userRoles.split(",").includes("Customer Admin") ||
            user?.userRoles.split(",").includes("Customer Accounting") ||
            user?.userRoles.split(",").includes("Customer User")) && (
            <React.Fragment>
               <li className="mb-2">
                <a
                    href="/Customer/Admin/CustomerUsers"
                    className="px-4 py-3 d-block text-decoration-none text-white"
                    title="Customer Users"
                >
                    <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-15"
                  >
                    <path
                      d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13M16 3.13C16.8604 3.3503 17.623 3.8507 18.1676 4.55231C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89317 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z"
                      stroke="#02A4E3"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                    Customer Users
                </a>
              </li>
            </React.Fragment>
          )}
        </ul>
      </div>

    </div >

  );
};
