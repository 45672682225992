import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, LinearProgress, MenuItem, OutlinedInput, Select, SelectChangeEvent, SelectProps, Switch } from '@mui/material';
import useAuth from "../../context/AuthProvider";
import { selectOptions, UsersModel, UsersModel_VS } from '../../models/UsersModel';
import { Field, FieldArray, FieldAttributes, Form, Formik, useField, useFormik, useFormikContext } from 'formik';
import { config } from '../../Constants';
import { FMTextField } from '../Elements/input';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import CancelIcon from "@mui/icons-material/Cancel";
import Swal from 'sweetalert2';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { useDropzone } from 'react-dropzone';
import { apiClient } from '../../helper/api';
import { FMSelectRolesCustomcopy } from './FMSelectRolesCustomcopy';

var formData = new FormData();
interface Iprops {
    UsersBL: UsersModel,
    handleClose: () => void,
    Open: boolean,
    handleSubmit: () => void
    initValues: UsersModel
    setSelectedOption: React.Dispatch<React.SetStateAction<string | string[]>>
    selectedOptions: string[] | string
    setSelectedRoles: React.Dispatch<React.SetStateAction<string | string[]>>
    SelectedRoles: string[] | string
    // imageProfile: string
    // setImageProfile: React.Dispatch<React.SetStateAction<string>>
    
}

export const AddEditCustomerUsers: React.FC<Iprops> = ({ handleClose, Open, UsersBL, handleSubmit, initValues, selectedOptions, setSelectedOption, SelectedRoles, setSelectedRoles }) => {
    const initialValues: UsersModel = useMemo(() => {
        return UsersBL ? UsersBL : initValues;
      }, [UsersBL, initValues]);

      const [SipAccess, SetSipAccess] = useState<boolean>(false);
    

    useEffect(() => {
        SetSipAccess(initialValues.isSIPAccess ?? false);
    }, [initialValues])

    const baseStyle = {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        borderWidth: 1,
        borderRadius: 8,
        borderColor: '#41729F',
        borderStyle: 'dashed',


        backgroundColor: '#fafafa',
        // color: '#bdbdbd',
        color: '#41729F',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const focusedStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };
    // const [imageProfile, setimageProfile] = useState<UsersModel>(UsersBL);
    function GenericStyledDropZone(props: any) {
        const {
            getRootProps,
            getInputProps,
            acceptedFiles,
            isFocused,
            isDragAccept,
            isDragReject
        } = useDropzone({
            maxFiles: 1,
            accept: { 'image/*': [] }, onDrop:
                (files) => {

                    var url = URL.createObjectURL(files[0]);
                    // setImageProfile(url);

                    formData.delete("profilePicture");
                    formData.append("profilePicture", files[0]);
                    // console.log("imageProfile", imageProfile);
                }
        });

        const style = useMemo(() => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }), [
            isFocused,
            isDragAccept,
            isDragReject
        ]);


        const files = acceptedFiles.map
            (
                file =>
                (
                    <React.Fragment>
                        <FilePresentIcon fontSize='inherit' />
                        <li key={file.name}>
                            {file.name} - {(file.size / 1024).toFixed(2)} kb
                        </li>
                    </React.Fragment>
                )

            );

        return (
            <React.Fragment>
                <div className="ps-0 card-body col-lg-12">

                    <div {...getRootProps({ style })}>

                        <input {...getInputProps()} name={"Profile Picture"} />
                        <p>Upload Profile picture  <span style={{ color: "red" }}></span> </p>

                    </div>
                    <aside>
                        <ul>{files}</ul>
                    </aside>
                </div>

            </React.Fragment>
        );
    }


    const handleResetImage = () => {
        // setImageProfile('');
    };
    const { user } = useAuth();
    const token = user?.token;

    const params = useParams();

    const initialPartnerdata = { partnersId: 0, customerName: "" };
    const [Partner, setpartnerData] = React.useState([initialPartnerdata]);
    

    const fetchData = () => {
        apiClient('/Partners/getPartners',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (!responseJson) return
                // Do something with the response
                setpartnerData(responseJson.data)

            })
    }
    const FMSelectPartnersCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);

        useEffect(() => {
        }, [])

        return (

            <FormControl >
                <FormHelperText className='formtexthelper-heading' >
                    Customer/Partner
                </FormHelperText>
                {/* <InputLabel id="State-Select-Label">Customer/Partner </InputLabel> */}
                <Select
                    {...field}
                    disabled={user?.userRoles.split(",").includes("Super Admin") ? false : initialValues.roleIds == "2" ? true : (user?.userRoles.split(",").includes("MISC Filer") && initialValues.roleIds != "5" || "") ? false : true}
                    labelId="State-Select-Label"
                    className="border-round custom-menu-item"
                    IconComponent={ExpandMoreIcon}
                    label={null}>
                    {/* <MenuItem value={0} >Customer/Partner </MenuItem> */}
                    {Partner.map((Name, key) =>
                        <MenuItem key={key} value={Name.partnersId}> {Name.customerName} </MenuItem>

                    )}
                </Select>

            </FormControl>

        )
    }

    const initialdata = { roleId: 0, roleName: "" };
    const [Roles, setRoles] = React.useState([initialdata]);
    
    const fetchRoleData = () => {
        apiClient('/Users/getAllRoles',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (!responseJson) return;
    
                // Sirf IsCustomerRole = 1 wale roles filter karna
                const filteredRoles = responseJson.data.filter((role: any) => role.isCustomerRole === true);
                // Filtered data setRoles me set karna
                setRoles(filteredRoles);
            })
            .catch(error => console.error("Error fetching roles:", error));
    };
    

   


    const [loading, setloading] = React.useState(false);
    const [username, setUsername] = React.useState('');
    const [usernameExists, setUsernameExists] = React.useState(false);
    const checkUsernameExists = async (value: string) => {
        setUsername(value);
        apiClient(`/Users/checkUsernameExist?email= ${value}`,
            {
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (!responseJson) return
                setUsernameExists(responseJson.data)
                setloading(false);

            })

    };

    useEffect(() => {
        fetchRoleData();
        fetchData();
    }, [])

    
    return (
        <Dialog open={Open} onClose={handleClose} maxWidth='xl' >
            {loading && <LinearProgress style={{ margin: 2 }} />}
            {initialValues.userId === 0 ? <h2 style={{ marginLeft: "1vw" }} className='card-title' >Create Customer User</h2> : <h2 style={{ marginLeft: "1vw" }} className='card-title'>Edit User</h2>}
            <Formik initialValues={initialValues}
                enableReinitialize={true}
                validateOnChange={true}
                validationSchema={UsersModel_VS}
                onSubmit={(data, { setSubmitting, setErrors }) => { 
                    var ids = data.roleIds?.split(',')
                    if (ids && ids.length < 1) {
                        console.log("first if", ids.length);
                        setErrors({ roleIds: 'Please select Role' });
                        setSubmitting(false);
                        return;
                    }
 
                    if ((data?.partnersId < 1)) {

                        alert("Customer required");
                        setSubmitting(false);
                        return;
                    }
                    const requestData = {
                        ...data,  // Spread existing data
                        isSIPAccess: SipAccess // Ensure it is included
                    };

                    setSelectedOption([]);
                    
                    setloading(true);
                    apiClient('/Users/AddUpdate',
                        {
                            method: 'POST',
                            headers: new Headers({
                                 'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            body: JSON.stringify(requestData)
                           

                        })
                        .then((res) => {
                            console.log(res);
                            if (res.resp != 1) {
                                handleSubmit();
                                setSubmitting(false);
                                Swal.fire({
                                    title: "Data Successfully saved",
                                    text: "",
                                    icon: "success",
                                    confirmButtonText: "OK",
                                });
                                // setImageProfile('');
                                handleClose();
                                // formData.delete("data");
                                //  formData.delete("profilePicture");
                               

                            } else {

                                alert(res.message);
                                setSubmitting(false);
                                
                            }

                        }).finally(() => {
                            setloading(false);
                        });
                }} >
                {({ values, isSubmitting, setFieldValue, errors }) => {
                    return (
                        <Form className='main-form'>

                            <DialogContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>

                                        <FormControl sx={{ width: '100%' }}>
                                            <FormHelperText className='formtexthelper-heading' >
                                                First Name
                                            </FormHelperText>
                                            <FMTextField name="firstName" autoFocus margin="dense" label={null} type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <FormHelperText className='formtexthelper-heading' >  Last Name </FormHelperText>
                                            {/* <FormHelperText>Last name</FormHelperText> */}
                                            <FMTextField name="lastName" autoFocus margin="dense" label={null} type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <FormHelperText className='formtexthelper-heading' >
                                                Username
                                            </FormHelperText>
                                            <FMTextField name="userName" autoComplete='off' autoFocus margin="dense" label={null} type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <FormHelperText className='formtexthelper-heading' >
                                                Email
                                            </FormHelperText>

                                            <FMTextField name="userEmail" autoFocus margin="dense" label={null} onChange={() => checkUsernameExists} type="text" fullWidth variant="outlined" sx={{ m: 2 }} />

                                        </FormControl>
                                    </Grid>
                                    {!(user?.userRoles.split(",").includes("Customer Accounting") || user?.userRoles.split(",").includes("Customer Admin")) &&
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <FMSelectPartnersCustom name="partnersId" />
                                        </FormControl>
                                    </Grid>}
                                    <Grid item xs={6}>
                                        <FormHelperText className='formtexthelper-heading' >
                                            Roles
                                        </FormHelperText>
                                        <FormControl sx={{ width: '100%' }} >

                                            <FMSelectRolesCustomcopy name="roleIds" UsersBL={UsersBL} Roles={Roles} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={SipAccess}
                                                    onChange={(e) => SetSipAccess(e.target.checked)}
                                                    color="primary"
                                                />
                                            }
                                            label="SIP Access"
                                        />
                                    </Grid>
                                </Grid>


                            </DialogContent>
                            <DialogActions>
                                <button className=" btn-2 btn d-flex btn-primary search-btn " onClick={handleClose} type='button' >Cancel</button>
                                <button className="btn  btn-primary active-btn-blue  search-btn " type="submit" disabled={isSubmitting}>Submit</button>

                            </DialogActions>


                        </Form>

                    );
                }}


            </Formik>
        </Dialog >
    );

}


