import { DataGrid, GridColDef, GridToolbarFilterButton } from '@mui/x-data-grid';
import React, { useEffect, useState } from "react";
import Moment from 'moment';
import { UsersModel } from '../../models/UsersModel';
import { Box, Button, IconButton, Link, SelectChangeEvent } from '@mui/material';
import { config } from '../../Constants';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { AddEditUsers } from '../AddEdit/AddEditUsers';
import useAuth from "../../context/AuthProvider";
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { RiExpandUpDownLine } from "react-icons/ri";
import { apiClient } from '../../helper/api';
import { AddEditCustomerUsers } from '../AddEdit/AddEditCustomerUsers';
interface Iprops {
    UsersCL: UsersModel[],
    fetchData: () => void
}



export const CustomerUsersList: React.FC<Iprops> = ({ UsersCL, fetchData }) => {
    const [rows, setRows] = React.useState(UsersCL);
    const initialUserData: UsersModel = {
        userId: 0,
        userName: "",
        password: "",
        isActive: true,
        isDeleted: false,
        dateCreated: new Date(),
        datemodified: new Date(),
        userEmail: "",
        firstName: "",
        lastName: "",
        modifiedBy: 0,
        createdBy: 0,
        roleIds: "",
        partnersId: 0,
        userRoles: '',
        customerName: '',
        // profilePicture: '',

    }


    const [Open, setOpen] = React.useState(false);
    const [loadingPartner, setloadingPartner] = React.useState(true);
    const [Formdata, setFormData] = React.useState(initialUserData);
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    const { user } = useAuth();
    const token = user?.token;
    const [users, setUsers] = React.useState([])
    const [loading, setloading] = React.useState(false);

    const getpartnerfetch = () => {
        setloading(true);

        apiClient('/Users/getLoggdInUserPartnerId',
            {
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((res) => {
                {
                    initialUserData.partnersId = user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer") ?
                        0
                        : Number(res.data)
                }
                setFormData(initialUserData);
                setOpen(true);
                setloadingPartner(false);
                console.log("in fetchuser", initialUserData);
            }).finally(() => {
                setloading(false);
            });


    }

    useEffect(() => {
        // getpartnerfetch();
    }, [])
    const [selectedOptions, setSelectedOption] = React.useState<string[] | string>([]);

    const columns: GridColDef[] = [

        {
            field: 'firstName', type: 'string', headerName: 'First Name', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), flex: 0.8, headerAlign: 'center', align: 'center'
        },
        {
            field: 'lastName', type: 'string', headerName: 'Last Name', flex: 0.8, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center'
        },
        {
            field: 'userName', headerName: 'Username', width: 130, flex: 0.8, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center'
        },
        {
            field: 'userEmail', type: 'string', headerName: 'Email ', flex: 1, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center'
        },
        {
            field: 'dateCreated', type: 'date', headerName: 'Created Date ', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center', flex: 0.8, renderCell: (params) => (<div> {params.row.dateCreated ? Moment(params.row.dateCreated).format("YYYY-MM-DD") : ""}</div>)
        },
        {
            field: 'userRoles', type: 'string', headerName: 'User Type', flex: 1, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center'
        },
        {
            field: 'customerName', type: 'string', headerName: 'Customer/Partner', flex: 1, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center'
        },

        {
            field: 'isActive', type: 'text', headerName: 'Active user', flex: 0.6, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center', renderCell: (params) => (
                params.row.isActive === true ? "Active" : "InActive")
        },
        {
            field: 'userId', flex: 1, headerAlign: 'center', align: 'center', headerName: "Action", sortable: false, hideSortIcons: true,
            renderCell: (params) => (<div>

                <IconButton
                    color="primary"
                    onClick={() => { handleEdit(params.row) }}

                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 3C17.2626 2.73736 17.5744 2.52902 17.9176 2.38687C18.2608 2.24473 18.6286 2.17157 19 2.17157C19.3714 2.17157 19.7392 2.24473 20.0824 2.38687C20.4256 2.52902 20.7374 2.73736 21 3C21.2626 3.26264 21.471 3.57445 21.6131 3.91761C21.7553 4.26077 21.8284 4.62857 21.8284 5C21.8284 5.37143 21.7553 5.73923 21.6131 6.08239C21.471 6.42555 21.2626 6.73736 21 7L7.5 20.5L2 22L3.5 16.5L17 3Z" stroke="#02A4E3" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </IconButton >
                {user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("Customer Accounting") || user?.userRoles.split(",").includes("Customer Admin") ?
                    <IconButton
                        color="error"
                        onClick={() => { handleDelete(params.row.userId) }}

                    >
                        {params.row.isDeleted ? <RestoreFromTrashIcon /> : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#162E3C" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        }
                    </IconButton >
                    : ""}
            </div>)
        },
    ];

    const handleClickOpen = () => {
        {
            (user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer")) ?
                setSelectedOption([])
                : setSelectedOption(["6"])
        }
        getpartnerfetch();
    };
    const [SelectedRoles, setSelectedRoles] = React.useState<string[] | string>([]);
    const handleClose = () => {
        setFormData(initialUserData);
        setSelectedOption([]);
        setImageProfile('');


        fetchData();
        setOpen(false);
    };
    const handleSubmit = () => {
        fetchData();
        setOpen(false);

    };
    const [imageProfile, setImageProfile] = useState<string>("");
    const handleEdit = (ILobData: UsersModel) => {

        
        const commaSeparatedIds: string[] = ILobData.roleIds?.split(',') || [];
      
        const RoleIds: number[] = commaSeparatedIds.map(id => Number(id));


        let CheckData: UsersModel = {
            userEmail: ILobData.userEmail,
            firstName: ILobData.firstName,
            lastName: ILobData.lastName,
            roleIds: RoleIds.toString(),
            userId: ILobData.userId,
            dateCreated: new Date(),
            partnersId: 0,
            // profilePicture: ILobData.profilePicture,
        }

        // setFormData(CheckData);
        let abc = ILobData.roleIds?.split(',') ?? [];
        let f = abc.map(e => e.trim());
    
        setSelectedRoles(f);
        setSelectedOption(f);
        ILobData.password = "";
        setFormData(ILobData);
        // setImageProfile(ILobData.profilePicture ?? "");
        setOpen(true);
     
    };


    const handleDelete = (id: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            // icon: null, // Remove the icon property
            iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        })
            .then((result) => {
                if (result.isConfirmed) {
                    apiClient("/Users/Delete?id=" + `${id}`,
                        {
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            method: "Delete",
                        }
                    )
                        .then((res) => {
                            if (!res) return
                            fetchData();
                            setOpen(false);
                        })
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )
                }
            })
        //call api to save data
        // fetch(config.url.API_URL + "/Users/Delete?id=" + `${id}`,
        //     {
        //         headers: new Headers({
        //             'Content-Type': 'application/json',
        //             'Authorization': `Bearer ${token}`,
        //         }),
        //         method: "Delete",
        //     }
        // )
        //     .then((response) => {
        //         if (response.ok) {
        //             return response.json();
        //         }
        //         throw new Error('Something went wrong');
        //     })
        //     .then((res) => {
        //         alert("Data Successfully Deleted")
        //         fetchData();
        //         setOpen(false);
        //     }).catch((error) => {
        //         console.log(error)
        //     });


    };


    function EditToolbar() {
        return (
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    p: 1,
                }}
            >
                <div className="d-flex justify-content-between align-items-center mb-4"  >
                    <div className="">
                        <span className="card-title">Customer Users</span>
                    </div>
                    <div className="" >
                        <button
                            className="active-btn-blue   "
                            onClick={handleClickOpen}   >
                            Add User
                        </button>
                    </div>
                </div>



            </Box>

        );

    }
    return (
        <div style={{ height: '60vh', width: '100%' }}>
            <DataGrid

                getRowId={(r) => r.userId}
                rows={UsersCL}
                columns={columns}
                // pageSize={50}
                style={{ textAlign: 'center' }}

                components={{
                    Toolbar: EditToolbar,
                }}

                sx={
                    {
                        fontFamily: "'Proxima Nova', sans-serif;",
                        border: "0px",
                        fontSize: "16px",
                        '& .MuiDataGrid-columnHeaderTitle': {
                            whiteSpace: 'break-spaces',
                            lineHeight: 1,
                        },
                        '&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer': {
                            pl: 1,
                        },


                    }
                }
            />
            {<AddEditCustomerUsers
                handleClose={handleClose}
                Open={Open}
                UsersBL={Formdata}
                handleSubmit={handleSubmit}
                setSelectedOption={setSelectedOption}
                selectedOptions={selectedOptions}
                initValues={initialUserData}
                SelectedRoles={SelectedRoles}
                setSelectedRoles={setSelectedRoles}
                // imageProfile ={imageProfile}
                // setImageProfile={setImageProfile}

            ></AddEditCustomerUsers>}

        </div>)
}