import { Copyright } from "@mui/icons-material";
import { Box, Container, Grid, LinearProgress, Paper, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { UsersList } from "../components/Lists/UsersList";
import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { apiClient } from "../helper/api";


export const Users = () => {

    const [users, setUsers] = useState([])
    const [loading, setloading] = useState(false);

    const { user } = useAuth();
    const token = user?.token;
    const fetchData = () => {
        setloading(true);

        apiClient('/Users/getAllUsers?isCustomerUser=false',
            {
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((res) => {
                if (!res) return
                console.log("User",user)
                // Do something with the response
                setUsers(res.data);
                console.log("listData", res.data);
            }).finally(() => {
                setloading(false);
            });


    }


    useEffect(() => {
        fetchData();
    }, [])



    return (
       
    <UsersList UsersCL={users} fetchData={fetchData} />
    
    )

}